import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home.vue'),
  },
  {
    path: '/disasters/current',
    name: 'disaster_current',
    component: () => import('@/views/Disasters/Current.vue'),
  },
  {
    path: '/disasters/current/entry_sheets/create',
    name: 'disaster_entry_sheet_create',
    component: () => import('@/views/Disasters/EntrySheets/Create.vue'),
  },
  {
    path: '/disasters/current/entry_sheets/completed',
    name: 'disaster_entry_sheet_completed',
    component: () => import('@/views/Disasters/EntrySheets/Completed.vue'),
  },
  {
    path: '/notices/:id',
    name: 'notice_detail',
    component: () => import('@/views/Notices/Detail.vue'),
  },
  {
    path: '/help',
    name: 'help',
    component: () => import('@/views/Others/Help.vue'),
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('@/views/Others/PrivacyPolicy.vue'),
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import('@/views/Others/Terms.vue'),
  },
  {
    path: '*',
    name: 'not_found',
    component: () => import('@/views/Errors/NotFound.vue'),
  },
  {
    path: '*',
    name: 'server_error',
    component: () => import('@/views/Errors/ServerError.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
